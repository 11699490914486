import React from 'react';
import './App.css';
import ClipboardComponent from './Clipboard';

function App() {
  return (
    <div className="App">
      <ClipboardComponent />
    </div>
  );
}

export default App;
