/*
 * Project Name: Clipboard-Service
 * File Name: firebase.ts
 * Programmer: Kai Prince
 * Date: Wed, Dec 14, 2022
 * Description: This file contains Firebase interfaces to save to Firestore.
 */

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD03CNwGcVBz7LYDfyH3yX15rD7NRop0Ao",
  authDomain: "clipboard-service.firebaseapp.com",
  databaseURL: "https://clipboard-service-default-rtdb.firebaseio.com",
  projectId: "clipboard-service",
  storageBucket: "clipboard-service.appspot.com",
  messagingSenderId: "967421016008",
  appId: "1:967421016008:web:aec315601c59aa899fb2fd",
  measurementId: "G-DH1NB623NW",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const readClipboard = async () => {
  const docRef = doc(db, "clipboard", "latest");
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return "";
  }

  const value: string = docSnap.get("value");
  return value;
};

export const setClipboard = async (value: string) => {
  const docRef = doc(db, "clipboard", "latest");

  await setDoc(docRef, { value });
};

export const onChange = async (callback: (value: string) => void) => {
  const docRef = doc(db, "clipboard", "latest");
  const unsub = onSnapshot(docRef, {
    next: (snapshot) => {
      const value: string = snapshot.get("value");
      callback(value);
    },
  });
  return unsub;
};
