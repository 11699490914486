/*
 * Project Name: Clipboard-Service
 * File Name: clipboard.ts
 * Programmer: Kai Prince
 * Date: Sun, Dec 20, 2020
 * Description: This file contains state logic for the clipboard.
 */

import React, { useState, useEffect, useRef } from "react";
import * as driver from "./drivers/firebase";

const ClipboardComponent: React.FC = () => {
  const [clipboard, setClipboard] = useState("");
  const [outBuffer, setOutBuffer] = useState("");

  const isInitialMount = useRef(true);

  // Set up listen handlers
  useEffect(() => {
    const handleCreated = (message: string) => {
      setClipboard(message);
    };

    const unsub = driver.onChange(handleCreated);

    return () => {
      unsub.then((func) => func());
    };
  }, []);

  // Set up emit handlers
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      console.log("emitting", outBuffer);

      driver.setClipboard(outBuffer);
    }
  }, [outBuffer]);

  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;

    setClipboard(value);
    setOutBuffer(value);
  };

  const style = { width: "80vw", height: "80vh" };

  return (
    <div>
      <p>Clipboard:</p>
      <textarea
        onInput={handleInput}
        value={clipboard}
        style={style}
      ></textarea>
    </div>
  );
};

export default ClipboardComponent;
